<template>
  <v-main>
  <div class="main-content main-content_fw">
    <Card class="sticky-filters card-custom" v-on:keyup.enter="applyFilters">
    <template #content>
        <v-row>
          <v-col class="filters-column">
            <!-- <Calendar :placeholder="$t('date')" :touchUI="$isMobile() ? true : false" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" :showIcon="true" /> -->
            <Dropdown 
            class="custom-dropdown"
            v-model="filters.currency" :options="filteredCurrencies"
            :placeholder="$t('currency')" :filter="true" :emptyFilterMessage="$t('no_data')">
          </Dropdown>
          <Dropdown v-model="filters.group_by" :options="timePeriods"
          :placeholder="$t('time_period')" optionValue="value" optionLabel="text" :emptyFilterMessage="$t('no_data')">
        </Dropdown>
        <Dropdown v-model="filters.result" :options="results"
        :placeholder="$t('format')" optionValue="value" optionLabel="value" :emptyFilterMessage="$t('no_data')">
      </Dropdown>
      <date-range-picker 
      :autoApply="true"
      id="datepicker_id" :showDropdowns="true" ref="picker" 
      :locale-data="locale_data"
                v-model="dateRange" format="yyyy/mm/dd" opens="center"
                :max-date="today.toISOString()">
                <div slot="input" slot-scope="picker">{{ dateRange.startDate | date }} - {{ 
                  dateRange.endDate | date }}
                </div>
              </date-range-picker>
                <span class="p-buttonset">
                  <Button @click="deleteFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('delete')" icon="pi pi-trash" class="p-button-sm p-button-danger" />
                  <Button @click="generateReport" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('generate_report')" icon="pi pi-file-import" class="p-button-sm p-button-success" />
              </span>
          </v-col>
          </v-row>
            <!-- <v-chip-group>
              <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key" @click="removeFilter(key)">
                <template>
                {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
                </template>
                <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
              </v-chip>
            </v-chip-group> -->
    </template>
    </Card>
    <Card class="mt-2 card-custom" v-if="showReport">
      <template #content>
        <div class="report">
          <div class="report__header header-report">
              <h3 class="text-uppercase">
                {{ $t('general_report') }}
              </h3>
              <Divider />
              <h6 class="mt-1">
               from {{ getFormattedDates(filters.from_date) }} to {{ getFormattedDates(filters.end_date) }} by {{ getFormattedPeriod(filters.group_by) }}
              </h6>
          </div>
          <DataTable :value="totals" :rows="totals.length" :loading="loadingFlag"
               :scrollable="true"
               
               responsiveLayout="scroll"
               class="p-datatable-sm mb-1">
               <Column field="name" 
               :showFilterMenu="false" 
               :header="$t('totals')"
               bodyClass="bg-c-white"
               headerClass="bg-c-white text-uppercase"
               :styles="{ 'min-width': '12rem' }"
              >
                <template #body="{data}" :styles="{'min-width': '10rem'}">
                  <v-col class="pa-0 d-flex flex-wrap"><span class="font-weight-bold">{{ $t(data.name) }}</span></v-col>
                </template>
              </Column>
              <Column headerClass="justify-center bg-c-white" bodyClass="justify-end bg-c-white" 
              field="value" 
              :styles="{ 'min-width': '10rem' }">
                <template #body="{ data }">
                  <div class="d-flex align-center font-weight-bold" style="white-space: nowrap;">
                    {{ data.value }} 
                  </div>
                </template>
              </Column>
            </DataTable>
          <div class="report__table table-report">
            <DataTable :value="reports.items" :rows="10" :rowClass="customClass" :loading="loadingFlag"
                 :scrollable="true"
                 stripedRows
                 responsiveLayout="scroll"
                 class="p-datatable-sm">
                 <Column field="name" 
                 :showFilterMenu="false" 
                 frozen
                 bodyClass="bg-c-white"
                 headerClass="bg-c-white"
                 :styles="{ 'min-width': '12rem' }"
                >
                  <template #body="{data}" :styles="{'min-width': '10rem'}">
                    <v-col class="pa-0 d-flex flex-wrap"><span>{{ $t(data.name) }}</span></v-col>
                  </template>
                </Column>
                <!-- .values.date -->
                <Column v-for="(col, index) of reports.items[1].values" headerClass="justify-center" bodyClass="justify-end" :field="col.created_at" :header="col.created_at" :key="index" :styles="{ 'min-width': '10rem' }">
                  <template #body="{ data }">
                    <div class="d-flex align-center" style="white-space: nowrap;">{{ getValueForDate(data, col.created_at) }} </div>
                  </template>
                </Column>
                <!-- <template #footer>
                  <div class="text-left ml-3"> {{ $t('total') }}</div> 
              </template> -->
                <!-- <Column field="date" :header="$t('date')" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
                  <template #body="{data}">
                    <div>
                      <div class="d-flex flex-column" v-for="date in data.name.values">
                        {{ date.date }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column field="value" :header="$t('value')" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
                  <template #body="{data}">
                    <div>
                      <div class="d-flex flex-column" v-for="date in data.name.values">
                        {{ date.value }}
                      </div>
                    </div>
                  </template>
                </Column> -->
              </DataTable>
          </div>
        </div>
      </template>
    </Card>
  </div>
  </v-main>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { mapGetters } from 'vuex';
  export default {
    components:{
      DateRangePicker
    },
      name: 'GeneralReport',
      data() {
          return {
              currentLocale: this.$i18n.locale || 'en',
              today: new Date(),
              loadingFlag: true,
              showReport: false,
              activeFilters:{},
              datesToFilter: [],
              generatedCurrency: '',
              dateRange:{},
              filters:{
                currency: 'USD',
                group_by: 'D',
                result: 'json',
              },
              results: [
              {
                value: 'json'
              },
              {
                value: 'pdf'
              },
              ],
              locale_data: {
      direction: 'ltr',
      format: 'mm.dd.yyyy',
      separator: ' - ',
      applyLabel: this.$i18n.t('applyLabel'),
      cancelLabel: this.$i18n.t('cancelLabel'),
      weekLabel: this.$i18n.t('weekLabel'),
      customRangeLabel: this.$i18n.t('customRangeLabel'),
      daysOfWeek: [
        this.$i18n.t('sun'), 
      this.$i18n.t('mon'),
       this.$i18n.t('tue'), 
       this.$i18n.t('wed'), 
       this.$i18n.t('thu'), 
       this.$i18n.t('fri'), 
       this.$i18n.t('sat')],
      monthNames: [this.$i18n.t('jan'), this.$i18n.t('feb'), this.$i18n.t('mar'), this.$i18n.t('apr'), this.$i18n.t('may'), this.$i18n.t('jun'), this.$i18n.t('jul'), this.$i18n.t('aug'), this.$i18n.t('sep'), this.$i18n.t('oct'), this.$i18n.t('nov'), this.$i18n.t('dec')],
      firstDay: 0,
    },
              timePeriods:[
                {
                text: this.$t('day'), value: 'D'
              },
                {
                text: this.$t('week'), value: 'W'
              },
                {
                text: this.$t('month'), value: 'M'
              },
                {
                text: this.$t('year'), value: 'Y'
              }
            ],
          }
      },
      filters: {
  date(val) {
   return val ? moment(val).format("YYYY-MM-DD") : "";
 }
},
      computed:{
          ...mapGetters({
              reports: 'reports/getGeneralReport',
              totals: 'reports/getGeneralReportTotals',
              currencies: 'getCurrencies',
              
          }),
          filteredCurrencies(){
    return this.currencies.filter(currency => currency !== 'ALL');
  }, 
      },
      beforeRouteEnter(to, from, next){
  next(vm => {
    vm.$store.commit('setPageName', 'general_report')
  });
  },
      async mounted(){
        this.dateRange = { startDate: moment().subtract(6, 'days'), endDate: moment() }
          this.loadingFlag = false;
          this.activeFilters = {};
          
      },
      methods:{
        getFormattedDates(date){
          return moment(date).format('YYYY-MM-DD')
        },
        getFormattedPeriod(period){
          switch (period) {
            case 'D':
            return this.$t('day');
            case 'W':
                return this.$t('week');
                case 'M': 
                return this.$t('month');
                case 'Y':
                return this.$t('year');
          }
        },
        customClass(field){
           return [
                 {
                    //  'total': field.name.includes('total_'),
                     'title': field.values.length === 0,
                 }
             ];
        },
        getValueForDate(data, date) {
          const values = data.values;
          //console.log(date);
          //console.log(values);
          if (values.length === 0) {
            return '';
          }
          const matchingItem = values.find(item => item.created_at === date);
          return matchingItem && matchingItem.value ? matchingItem.value : 0;
          //if (data.name.includes('count')) {
          //  // console.log(data);
          //  return matchingItem && matchingItem.value ? `${matchingItem.value}` : `0`;
          //} else if(data.name.includes('percent')){
          //  return matchingItem && matchingItem.value ? `${matchingItem.value}%` : `0%`;
          //} else{
          //
          //   return matchingItem && matchingItem.value ? `${matchingItem.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.generatedCurrency}` : `0`;
          //}
  },
          async generateReport(){
              this.loadingFlag = true;
              this.filters.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
              this.filters.end_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
              this.filters.tz_offset = moment(this.dateRange.startDate).format('Z')
              //var originalDate = moment(this.dateRange.startDate, "ddd MMM DD YYYY HH:mm:ss GMTZ");
              // this.filters.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
              // this.filters.end_date = moment(this.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

              try {
                await this.$store.dispatch('reports/getGeneralReport', {filters: this.filters});
                this.generatedCurrency = this.filters.currency;
                if (this.filters.result !== 'pdf') {
                  this.showReport = true;
                }
                this.$toast.add({severity: 'success', summary: this.$t('success_report'), life: 3000}) 
               } catch (error) {
                 this.$toast.add({severity: 'error', summary: this.$t('just_error'), life: 3000}) 
               } finally{

                 this.loadingFlag = false;
               }
          },
          deleteFilters(){
              this.loadingFlag = true;
              this.showReport = false;
              this.datesToFilter = [];
              this.$nextTick(() =>{
                this.loadingFlag = false;
              })
          },
      },
  }
</script>

<style lang="scss" scoped>
.report{
  overflow: auto;
}

.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    //top: 0;
    //left: 0;
    //position: absolute;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
.filtersCard {
  width: 100%;
  z-index: 3;
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  @media screen and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
        position: static !important;
    }
}
</style>